/* eslint-disable unicorn/no-null */
import { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import {
  selectAmounts,
  // selectPaymentIntentId,
  // setPaymentIntentId,
} from '../../../features/signUp/signUpSlice';
import { useAppSelector } from '../../../stores/AppStore';
import { handleError } from '../../../services/ErrorService';
import EmbeddedCardForm from './EmbeddedCardForm';
import {
  useCreatePaymentIntentMutation,
  // useCreateSubscriptionMutation,
} from '../../../services/apiSlice';

interface PaymentProperties {
  onNextStep: () => void;
  onPreviousStep: () => void;
}

const Payment = ({ onNextStep, onPreviousStep }: PaymentProperties) => {
  // NOTE: the current CircleCI setup uses the production environment at build time and
  // this branching doesn't work as expected. The wrong value will be fixed at deploy time
  const stripePublicKey =
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_STRIPE_PUBLIC_KEY
      : process.env.REACT_APP_STRIPE_PUBLIC_KEY_SANDBOX;
  if (!stripePublicKey) {
    Sentry.captureException(new Error('Stripe public key not found'));
  }
  const amounts = useAppSelector(selectAmounts);
  // const paymentIntentId = useAppSelector(selectPaymentIntentId);
  const [isLoading] = useState(false);
  const [, { error: paymentIntentError, isLoading: paymentIntentLoading }] =
    useCreatePaymentIntentMutation();
  // const [
  //   ,
  //   { error: createSubscriptionError, isLoading: createSubscriptionLoading },
  // ] = useCreateSubscriptionMutation();

  const stripeOptions = {
    // mode: 'payment',
    mode: 'subscription',
    amount: amounts?.amount,
    currency: 'usd',
    paymentMethodTypes: ['card'],
  } as StripeElementsOptions;
  console.log({ stripeOptions });

  return (
    <>
      {isLoading || paymentIntentLoading ? (
        <p className="text-sm text-gray-500 text-center w-full p-4">
          Setting Up Secure Payment...
        </p>
      ) : paymentIntentError ? (
        <p className="text-sm text-rose-500 text-center w-full p-4">
          There was an error initiating the payment. Please try again later or
          contact us.
        </p>
      ) : (
        <ErrorBoundary
          FallbackComponent={StripeErrorFallback}
          onError={handleError}
        >
          {stripePublicKey ? (
            <Elements
              // key={clientSecret}
              stripe={loadStripe(stripePublicKey)}
              options={stripeOptions}
              // options={{ clientSecret }}
            >
              <EmbeddedCardForm
                onNextStep={onNextStep}
                onPreviousStep={onPreviousStep}
                // paymentIntentId={paymentIntentId}
              />
            </Elements>
          ) : (
            <p className="text-sm text-rose-500 text-center w-full p-4">
              There was an error initiating the payment. Please try again later
              or contact us.
            </p>
          )}
        </ErrorBoundary>
      )}
    </>
  );
};

const StripeErrorFallback = () => {
  return (
    <div className="flex flex-col p-4">
      <p className="text-sm text-gray-600 text-center w-full">
        Something went wrong while processing your payment.
      </p>
      <p className="text-sm text-gray-600 text-center w-full">
        Please try again later or{' '}
        <a
          href="mailto:help@thinktankprep.com?subject=Subscription Issue"
          target="_blank"
          className="text-tttDefault hover:underline"
          rel="noreferrer"
        >
          contact us.
        </a>
      </p>
    </div>
  );
};

export default Payment;
