/* eslint-disable unicorn/consistent-function-scoping */
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Address, StripeAddressElementChangeEvent } from '@stripe/stripe-js';
import toast from 'react-hot-toast';

import {
  selectAmounts,
  selectMentor,
  selectNumberOfLicenses,
  // selectPaymentIntentId,
  selectTrackGroup,
  setAmounts,
  setEmail,
  setPaymentIntentId,
} from '../../../features/signUp/signUpSlice';
import { handleError } from '../../../services/ErrorService';
import { store, useAppSelector } from '../../../stores/AppStore';
import {
  useCreatePaymentIntentMutation,
  useLazyCalculateTaxQuery,
  // useUpdatePaymentIntentAmountMutation,
} from '../../../services/apiSlice';
import { getStripeAddressElementOptions } from '../../../constants';

import OrderDetails from './OrderDetails';
import { Link } from 'react-router-dom';
import { BillingPeriod } from '../../../types';

interface EmbeddedCardFormProperties {
  onNextStep: () => void;
  onPreviousStep: () => void;
  // paymentIntentId: string;
}

const EmbeddedCardForm = ({
  onNextStep,
  onPreviousStep,
}: // paymentIntentId,
EmbeddedCardFormProperties) => {
  const stripe = useStripe();
  const elements = useElements();
  const [{ addressBlocked, cardBlocked }, setBlocked] = useState({
    addressBlocked: true,
    cardBlocked: true,
  });
  const mentor = useAppSelector(selectMentor);
  const [address, setAddress] = useState<Address>();
  const [name, setName] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const amounts = useAppSelector(selectAmounts);
  const numberOfLicenses = useAppSelector(selectNumberOfLicenses);
  const trackGroup = useAppSelector(selectTrackGroup);
  // const paymentIntentId = useAppSelector(selectPaymentIntentId);
  const [calculateTax, { isFetching }] = useLazyCalculateTaxQuery();
  // const [updatePaymentIntentAmount] = useUpdatePaymentIntentAmountMutation();
  const [createPaymentIntentMutation, { error: paymentIntentError }] =
    useCreatePaymentIntentMutation();

  const isEmailValid = /[\da-z]+@[\da-z]+\.[\da-z]+/.test(mentor.email);

  const handleAddressChange = async (
    event: StripeAddressElementChangeEvent,
  ) => {
    // amount missing
    if (!amounts?.amount) {
      return;
    }
    // address incomplete
    if (!event.complete) {
      setBlocked((previous) => ({ ...previous, addressBlocked: true }));
      return;
    }
    // address unchanged
    if (
      address &&
      address.city === event.value.address.city &&
      address.postal_code === event.value.address.postal_code &&
      address.state === event.value.address.state
    ) {
      return;
    }
    setName(event.value.name);
    setAddress(event.value.address);
    const result = await calculateTax(
      {
        address: event.value.address,
        amount: amounts.amount - (amounts.discount ?? 0),
      },
      false,
    );
    if (!result.data || result.error) {
      store.dispatch(
        setAmounts({
          ...amounts,
          tax: undefined,
          total: amounts.amount - (amounts.discount ?? 0),
        }),
      );
      toast.error('Failed to calculate tax. Please try again in a minute.');
      return;
    }
    store.dispatch(
      setAmounts({
        ...amounts,
        tax: result.data.tax_amount_exclusive,
        total: result.data.amount_total,
      }),
    );
    setBlocked((previous) => ({ ...previous, addressBlocked: false }));
  };

  const getClientSecret = async () => {
    setIsLoading(true);
    try {
      if (!amounts?.total) {
        return;
      }
      if (!address || !name) {
        console.log('missing address or name');
        // FIXME
        return;
      }
      const result = await createPaymentIntentMutation({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        amount: amounts.total,
        email: mentor.email,
        address,
        name,
        billingPeriod: (amounts.billingPeriod as BillingPeriod) ?? 'month',
        priceId: '', // FIXME
        licenseCount: numberOfLicenses,
        discountCode: amounts.discountCode,
      }).unwrap();
      if (paymentIntentError) {
        handleError(paymentIntentError);
        setIsLoading(false);
        return;
      }
      if (!result) {
        setIsLoading(false);
        return;
      }
      store.dispatch(setPaymentIntentId(result.paymentIntentId));
      return result.client_secret;
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handlePayment = async () => {
    if (!stripe || !elements || amounts?.total === undefined) {
      const errorMessage =
        'Could not process payment as a critical detail is missing.';
      Sentry.captureException(new Error(errorMessage), {
        tags: {
          function: 'handlePayment',
          stripe: typeof stripe,
          elements: typeof elements,
          totalAmount: amounts?.total,
        },
      });
      toast.error(
        errorMessage +
          ' Please try again or contact us at help@thinktankprep.com',
      );
      return;
    }
    setIsLoading(true);
    const secret = await getClientSecret();
    // if (!paymentIntentId) {
    //   console.log({ paymentIntentId });
    //   const errorMessage = 'Could not initiate payment.';
    //   Sentry.captureException(new Error(errorMessage), {
    //     tags: {
    //       function: 'handlePayment',
    //       paymentIntentId,
    //     },
    //   });
    //   toast.error(
    //     errorMessage +
    //       ' Please try again or contact us at help@thinktankprep.com',
    //   );
    //   return;
    // }
    // const updateResponse = await updatePaymentIntentAmount({
    //   paymentIntentId,
    //   amount: amounts.total,
    // });
    // if ('error' in updateResponse) {
    //   handleError(updateResponse.error);
    //   toast.error('Failed to update payment details.');
    //   setIsLoading(false);
    //   return;
    // }
    if (!secret) {
      return;
    }
    await elements.submit();
    // TODO: error checks
    const confirmPaymentResponse = await stripe.confirmPayment({
      elements,
      clientSecret: secret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/sign-up`,
        // payment_method_data: {
        //   billing_details: {
        //     name: mentor.firstName + ' ' + mentor.lastName,
        //     email: mentor.email,
        //     phone: mentor.phone,
        //   },
        // },
      },
      redirect: 'if_required',
    });

    if (confirmPaymentResponse.error) {
      let displayedError: string | undefined = '';
      switch (confirmPaymentResponse.error.type) {
        case 'validation_error' || 'card_error': {
          displayedError = confirmPaymentResponse.error.message;
          break;
        }
        case 'api_error' ||
          'api_connection_error' ||
          'idempotency_error' ||
          'authentication_error': {
          displayedError =
            'An error occurred while processing your payment. If this issue persists, please contact the support.';
          break;
        }
        case 'rate_limit_error': {
          displayedError =
            'Too many request while trying to process your payment. Please try again later.';
          break;
        }
        case 'invalid_request_error': {
          displayedError =
            'An error occured on our end while processing your payment. Please contact the support.';
        }
        default: {
          displayedError = 'An unexpected error occurred. Please try again.';
          break;
        }
      }
      handleError(confirmPaymentResponse.error, {
        displayedError,
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    onNextStep();
  };

  useEffect(() => {
    store.dispatch(
      setAmounts({
        ...amounts,
        tax: undefined,
        total: undefined,
      }),
    );
  }, []);

  return (
    <div className="mt-[-8px] md:mt-0 flex flex-col gap-2 md:gap-4 w-full h-full">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Order Details */}
        <OrderDetails
          // paymentIntentId={paymentIntentId}
          address={address}
          isApplyCouponBlocked={isLoading}
          setIsLoading={setIsLoading}
        />

        {/* Payment Elements */}
        {amounts?.amount && amounts?.amount > 0 ? (
          <div className={`flex flex-col gap-2 w-full`}>
            <h1 className="text-2xl md:text-3xl font-medium w-full pb-2 md:pb-4">
              Step 2: Payment Details
            </h1>
            <div data-field="name" className="p-Field">
              <label
                className="p-FieldLabel Label Label--empty font-"
                htmlFor="Field-emailInput"
              >
                Email
              </label>
              <div>
                <input
                  type="text"
                  id="Field-emailInput"
                  name="email"
                  autoComplete="email"
                  placeholder="Email address"
                  aria-invalid="false"
                  aria-required="true"
                  className="p-Input-input Input Input--empty border border-slate-200 w-full p-3 rounded-l"
                  value={mentor.email}
                  onChange={(event) => {
                    store.dispatch(setEmail(event.target.value));
                  }}
                />
              </div>
              <div className="AnimateSinglePresence"></div>
            </div>
            <AddressElement
              id="address-element"
              options={getStripeAddressElementOptions({
                // name: mentor.firstName + ' ' + mentor.lastName,
              })}
              onChange={handleAddressChange}
            />
            <PaymentElement
              id="payment-element"
              onChange={(event) =>
                setBlocked((previous) => ({
                  ...previous,
                  cardBlocked: !event.complete,
                }))
              }
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* Action Buttons */}
      <div
        className={`flex gap-2 pt-4 md:pt-6 w-full justify-center md:justify-between items-center`}
      >
        <button
          data-testid={`back-button`}
          type="button"
          className="
              px-10 py-2 w-max rounded-full border font-medium
              transition-all duration-300 ease-in-out
              hover:shadow hover:outline hover:outline-tttDefault hover:outline-[1px]
              disabled:opacity-50 disabled:cursor-not-allowed
            "
          onClick={onPreviousStep}
        >
          Back
        </button>
        <button
          data-testid={`continue-button`}
          onClick={handlePayment}
          disabled={
            isLoading ||
            isFetching ||
            addressBlocked ||
            cardBlocked ||
            !isEmailValid ||
            !trackGroup
          }
          title={
            addressBlocked
              ? 'Please enter your billing address first'
              : cardBlocked
              ? 'Please enter your card details'
              : trackGroup
              ? isEmailValid
                ? ''
                : 'Please enter a valid email address'
              : 'Please choose a learning path'
          }
          className="
            px-10 py-2 w-max rounded-full
            font-medium text-white bg-tttDefault
            transition-all duration-300 ease-in-out
            hover:shadow hover:bg-opacity-90
            disabled:opacity-50 disabled:cursor-not-allowed
          "
        >
          Pay Now
        </button>
      </div>

      <p className="text-xs text-gray-600">
        By clicking "Pay Now" you acknowledge that you have read and agree to
        the Think Tank's{' '}
        <Link to="/privacy-policy" target="_blank" className="text-tttDefault">
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link
          to="/terms-and-conditions"
          target="_blank"
          className="text-tttDefault"
        >
          Terms of Service
        </Link>
        .
      </p>
    </div>
  );
};

export default EmbeddedCardForm;
