import React from 'react';
import toast from 'react-hot-toast';

interface FlipSwitchProperties {
  disabledLeft?: boolean;
  disabledLeftWarning?: string;
  optionLeft: string;
  optionRight: string;
  onChange: (selected: 'left' | 'right') => void;
  selected: 'left' | 'right';
}

const FlipSwitch: React.FC<FlipSwitchProperties> = ({
  disabledLeft,
  disabledLeftWarning,
  optionLeft,
  optionRight,
  onChange,
  selected,
}) => {
  const handleClick = (option: 'left' | 'right') => {
    if (option !== selected) {
      onChange(option);
    }
  };

  return (
    <div className="relative inline-flex items-center w-64 h-12 px-4 py-2 bg-blue-200 rounded-full cursor-pointer transition-colors duration-300">
      <div
        className={`absolute top-1 left-0 h-10 w-1/2 rounded-full bg-tttDefault transform transition-transform duration-300 ease-in-out ${
          selected === 'left' ? 'translate-x-0' : 'translate-x-full'
        }`}
      />
      <span
        className={`absolute top-0 left-0 w-1/2 h-full flex items-center justify-center text-white font-semibold ${
          disabledLeft ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        onClick={() => {
          if (disabledLeft) {
            if (disabledLeftWarning) {
              toast.error(disabledLeftWarning);
            }
            return;
          }
          handleClick('left');
        }}
        title={disabledLeft ? disabledLeftWarning : ''}
      >
        {optionLeft}
      </span>
      <span
        className="absolute top-0 right-0 w-1/2 h-full flex items-center justify-center text-white font-semibold cursor-pointer"
        onClick={() => handleClick('right')}
      >
        {optionRight}
      </span>
    </div>
  );
};

export default FlipSwitch;
